import React from 'react';
import { graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import imageMediaForbes from '../assets/images/vectors/media/Forbes_logo.png';
import imageMediaSiliconReview from '../assets/images/vectors/media/TheSiliconReview.svg';
import imageMediaMedium from '../assets/images/vectors/media/Wordmark_Black.png';
import imageMediaBFH from '../assets/images/vectors/media/business-for-home-LOGO.png';
import imageMediaDigitalJournal from '../assets/images/vectors/media/Digital-Journal-Logo.png';

import '../assets/styles/pages/our-story-page.scss';
import { isClient } from '../helpers';

const OurStoryPage = ({ data }) => {
  const prismicData = data.prismic.allOur_story_pages.edges[0].node;

  if (isClient) {
    navigate('/'); // TODO: temporary disable this page
  }

  return <div />;
  // return ( // TODO: temporary disable this page
  //   <Layout>
  //     <SEO title="Our Story" />
  //     <div className="our-story-page">
  //       <FirstSection data={data} prismicData={prismicData} />
  //       <SecondSection data={data} prismicData={prismicData} />
  //       <ThirdSection prismicData={prismicData} />
  //       <FourthSection data={data} prismicData={prismicData} />
  //       <FifthSection prismicData={prismicData} />
  //       <SixthSection />
  //     </div>
  //   </Layout>
  // );
};

const FirstSection = ({
  prismicData: {
    first_section_subtitle,
    first_section_title,
    first_section_content,
    first_section_imageSharp,
  },
}) => (
  <section className="first-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-11 col-lg-10 mx-auto first-section__top">
          <div className="c-h1">{RichText.asText(first_section_subtitle)}</div>
          <div className="first-section__top__image">
            <Img fluid={first_section_imageSharp.childImageSharp.fluid} />
          </div>
        </div>
        <div className="col-12">
          <div className="first-section__bottom">
            <div className="first-section__bottom__wrapper">
              <div className="c-h1">{RichText.asText(first_section_title)}</div>
              <div>{RichText.render(first_section_content)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SecondSection = ({
  prismicData: { second_section_title, second_section_content, second_section_imageSharp },
}) => (
  <section className="second-section">
    <div className="container">
      <div className="row">
        <div className="col-md-6 second-section__image">
          <Img fluid={second_section_imageSharp.childImageSharp.fluid} />
        </div>
        <div className="col-md-6 second-section__text">
          <div className="c-h5--light">{RichText.asText(second_section_title)}</div>
          <div>{RichText.render(second_section_content)}</div>
        </div>
      </div>
    </div>
  </section>
);

const ThirdSection = ({
  prismicData: { third_section_title, third_section_content, third_section_image },
}) => (
  <section className="third-section">
    <div className="container">
      <div className="row">
        <div className="col-12 c-section-my">
          <div className="c-h1 text-center">{RichText.asText(third_section_title)}</div>
        </div>
        <div className="col-12">
          <div className="third-section__wrapper">
            <div className="third-section__text">{RichText.render(third_section_content)}</div>
            <div className="third-section__image">
              <img src={third_section_image.url} className="w-100" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
const FourthSection = ({
  prismicData: { fourth_section_title, fourth_section_content, fourth_section_imageSharp },
}) => (
  <section className="c-section-mt fourth-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 col-lg-9 mx-auto">
          <div className="fourth-section__wrapper">
            <Img fluid={fourth_section_imageSharp.childImageSharp.fluid} />
            <div className="fourth-section__text">
              <div className="c-h1">{RichText.asText(fourth_section_title)}</div>
              <div>{RichText.render(fourth_section_content)}</div>
            </div>
            <div className="fourth-section__label">Profiled in the inside cover of Forbes 2019</div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
const FifthSection = ({ prismicData: { fifth_section_cards } }) => (
  <section className="fifth-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 col-lg-9 mx-auto">
          <div className="fifth-section__founders">
            {fifth_section_cards.map((item, index) => (
              <div className="founder" key={index}>
                <img src={item.avatar.url} alt={RichText.asText(item.name)} />
                <div>
                  <div className="founder__name">{RichText.asText(item.name)}</div>
                  <div className="founder__position">{RichText.asText(item.position)}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);
const SixthSection = () => (
  <section className="c-section-my sixth-section">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="sixth-section__title">As Seen On</div>
          <div className="sixth-section__wrapper">
            <img src={imageMediaForbes} alt="" />
            <img src={imageMediaSiliconReview} alt="" />
            <img src={imageMediaMedium} alt="" />
            <img src={imageMediaBFH} alt="" />
            <img src={imageMediaDigitalJournal} alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default OurStoryPage;

export const query = graphql`
  query {
    prismic {
      allOur_story_pages {
        edges {
          node {
            first_section_subtitle
            first_section_title
            first_section_content
            first_section_image
            first_section_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1220, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            second_section_title
            second_section_content
            second_section_image
            second_section_imageSharp {
              childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            third_section_title
            third_section_content
            third_section_image
            fourth_section_title
            fourth_section_content
            fourth_section_image
            fourth_section_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1215, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            fifth_section_cards {
              avatar
              name
              position
            }
          }
        }
      }
    }
  }
`;
